module.exports = {
	xQuantitySpinner: () => {
		return {
			stepUp: function (input, dispatch) {
				input.stepUp();
				this.dispatchInput(input, dispatch);
			},
			stepDown: function (input, dispatch) {
				input.stepDown();
				this.dispatchInput(input, dispatch);
			},
			dispatchInput: function (input, dispatch) {
				dispatch("input", input.value);
			},
			spread: {
				['@submit.prevent']: 'submit'
			},
			disabled: false,
			submitting: false,
			stock: 1,
			isVisible: function(el) {
				return (el.offsetParent !== null)
			},
			submit: function () {
				if (!this.submitting) {
					var element = this.$el.closest("form");
					this.submitting = true;
					let body = new FormData(element);

					fetch(element.action, {
						body,
						method: element.method
					})
						.then(response => {
							if (response.status == 200) {
								response.text().then((s) => {
									let wrapper = document.querySelector('#savedBasketWrapper')
									if (document.querySelector('#modalBasketContent') != null && this.isVisible(document.querySelector('#modalBasketContent'))) {
										wrapper = document.querySelector('#modalBasketWrapper')
									}
									if (wrapper == null) {
										wrapper = document.querySelector('#basketWrapper')
									}

									if (s == "true") {
										var row = this.$el.closest("tr");
										row.classList.add("!hidden")
									}
									else {
										wrapper.innerHTML = s;
									}
								});
							}
							else {
								alert("failed increasing quantity");
							}

							this.submitting = false;
						});
				}
			}
		}
	}
}
