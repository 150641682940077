module.exports = {
    xAsyncForm: () => {
        return {
          submitting: false,
          open: false,
        
          submit: function submit(dispatch) {
              this.submitting = true
              setTimeout(() => {
                  this.submitting = false
                  this.open = false
                  dispatch('body-lock', false)
              }, 1500)
          }
        }
    }
}