module.exports = {
	xFavorites: () => {
		return {
			submitting: false,
			add() {
				if (!this.submitting) {
					this.submitting = true
					this.sku = this.$refs?.hiddenSku.value

					const data = { "Sku": this.sku }
					fetch('/umbraco/surface/FavoritesSurface/CreateFavorites', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(data),
					})
						.then(response => response.json())
						.then(data => {
							this.submitting = false

							// jens approved
							location.reload()
						})
						.catch((error) => {
							console.error('post error', error)
						})
				}
			},
			remove() {
				if (!this.submitting) {
					this.submitting = true
					this.id = this.$refs?.hiddenId.value

					const data = { "Id": this.id }
					fetch('/umbraco/surface/FavoritesSurface/DeleteFavorites', {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json',
						},
						body: JSON.stringify(data),
					})
						.then(response => response.json())
						.then(data => {
							this.submitting = false

							// jens approved
							location.reload()
						})
						.catch((error) => {
							console.error('post error', error)
						})
				}
			}
		}
	}
}
