import { debounce } from './../utils';

export function xOverflow() {
    return {
        overflow:false,

        // methods
        setFromResize() {
            this.overflow = this.$refs?.wrapper?.clientWidth < this.$refs?.content?.clientWidth
        },
        setFromScroll(e) {
            this.overflow = !(e.target.scrollLeft == this.$refs?.content?.clientWidth - this.$refs?.wrapper?.clientWidth);
        },

        init() {
            setTimeout(this.setFromResize, 250);
			window.addEventListener("resize", debounce(event => this.setFromResize, 250));
			this.$refs?.wrapper.addEventListener("scroll", debounce(event => this.setFromScroll, 250));
        }
    }
}
