module.exports = {
    xCutOffsConfigurator: function (config) {
        return {
            // props
			culture: config?.culture ?? 'en-GB',
			cutInTwo: false,
            currency: config?.currency ?? 'EUR',
			handlingFee: config?.handlingFee ?? 0.00,
			minCutLength: config?.productType == 'Custom' ? 1000 : config?.minCutLength ?? 0,
			maxCutLength: config?.maxCutLength ?? 0,
			customLengthMinOrder: config?.customLengthMinOrder ?? 0,
			maxTotalLength: config?.maxTotalLength,
            maxTolerance: config?.maxTolerance ?? 0,
            pricePerCut: config?.pricePerCut ?? 0.00,
			pricePerMeter: config?.pricePerMeter ?? 0.00,
            productPrice: config?.productPrice ?? 0.00,
			productQuantity: config?.productQuantity ?? 1,
			productStock: config?.productStock ?? 0,
			tolerance: config?.tolerance ?? '',
			toleranceOptions: config?.toleranceOptions ?? [],
			allToleranceOptions: config?.allToleranceOptions ?? [],
			defaultTolerance:  config?.defaultTolerance,
			vatRate: config?.vatRate ?? 0.00,
			sawWidth: config?.sawWidth ?? 0,
			sku: config?.sku ?? '',
			productType: config?.productType,
			buttonState: '',
			applyForMembershipUrl: config?.applyForMembershipUrl ?? '',
			isLoggedIn: config?.isLoggedIn ?? false,
			standardOutletProductTypeAlias: 'StandardOutlet',

            // data
            cuts: config?.cuts ?? [],
			recycleWaste: `${config?.wasteIsRecycleable}` ?? '',
			showRecycleWasteWarning: false,
            show: false,
			showDetails: false,

			init() {
				this.$watch('show', () => this.show ? this.cutInTwo = false : "")
			},

            // computed values
			get serialized() {
				var model = {
					Sku: this.sku,
					Lines: this.cuts.map(cut => {
						return {
							Length: cut.length,
							ToleranceId: cut.toleranceId,
							Quantity: cut.quantity,
						};
					}),
					WasteIsRecycleable: this.isStandardOrOutlet ? this.recycleWaste : null,
					ProductType: this.productType
				};
				return JSON.stringify(model);
			},
			get isStandardOrOutlet(){
				return this.productType == this.standardOutletProductTypeAlias;
			},
			get totalProductPrice() {
				if (this.isStandardOrOutlet) {
					// The customer only pays the productprice - no meter calculation
					let realProductPrice = this.productPrice;
					return this.productQuantity * realProductPrice;
				}
				else {
					// multiply the price by cut metres for 'made to order' C-sortiment products
					this.handlingFee = 0;
					this.pricePerCut = 0;
					let priceFactor = this.minCutLength > 0 ? (this.totalCutsLength == 0 ? 0 : this.totalCutsLength / 1000) : 1;
					let realProductPrice = this.productPrice * priceFactor;
					return this.productQuantity * realProductPrice;
				}
			},
            get totalCuts () {
				//The most simple solution. Small edgecase where we would add 1 cut too many if the lengths of the cut match perfectly with the profile length
                let cutCount = 0;
				this.cuts.map(cut => { cutCount += parseInt(cut.quantity) });
                return cutCount * this.productQuantity;
            },
            get totalCutPrice() {
                return this.pricePerCut * this.totalCuts;
            },
            get totalPrice() {
                return this.totalProductPrice + this.handlingFee + this.totalCutPrice;
            },
            get totalVat() {
                return this.totalPrice * this.vatRate;
            },
            get totalCutsLength() {
                var l = 0;
                this.cuts.map(cut => l = l + this.cutLength(cut));
                return l;
            }, 
			get allCutsValid(){
				var isCutsValid = this.isStandardOrOutlet && this.recycleWaste == '' ? false : true;
				this.cuts.forEach(cut => {
					if(!this.cutIsValid(cut)){
						isCutsValid = false;
					}
				});

				var notTooLong = !this.cutsIsTooLong;
				var notTooShort = !this.cutsIsTooShort;
				

				return isCutsValid && notTooLong && notTooShort;
			},
            get cutsIsTooLong() {
				if(this.isStandardOrOutlet){
					return this.totalCutsLength > this.maxTotalLength;
				}
				else{
					//Made to order (c-sortiment) has no upper limit for total length
					return false;
				}
                
			},
			get displayMetersLeft(){
				var mm = ((this.maxTotalLength - this.totalCutsLength) / 1000);
				return this.displayDigits(mm);
			},
			get lengthLeft() {
				if (this.isStandardOrOutlet && this.totalCutsLength < this.maxTotalLength) {
					return true;
				}
				else {
					return false;
				}
			},
            get cutsIsTooShort() {
				if(this.isStandardOrOutlet){
					//Not sure if the total cut can be too short - the rest would just be waste - many 1 cut minimum?
					return this.cuts.length <= 0;
					//return this.minCutLength > 0 && this.minCutLength > this.totalCutsLength;
				}else{
					///Made to order (c-sortiment) has to meet this requirement 
					return this.missingTotalLength > 0;
				}
               
            },
			get missingTotalLength(){
				///Missing length for 'Made to order (c-sortiment)' is valid
				return this.customLengthMinOrder - this.totalCutsLength
			},
            

            // methods
			recycleWasteIsValid: function (status) {
				if (this.isStandardOrOutlet && this.recycleWaste == '' && status === false) {
					this.showRecycleWasteWarning = true
				}
				else {
					this.showRecycleWasteWarning = false;
				}
			},
			displayDigitsInMM: function (number) {
				return new Intl.NumberFormat(this.culture, { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(number)
			},
			displayDigits: function (number) {
				return new Intl.NumberFormat(this.culture, { minimumFractionDigits: 3, maximumFractionDigits: 3 }).format(number / 1000)
			},
			stepUp: function (input, dispatch) {
				input.stepUp();
				this.dispatchInput(input, dispatch);
			},
			stepDown: function (input, dispatch) {
				input.stepDown();
				this.dispatchInput(input, dispatch);
			},
			dispatchInput: function (input, dispatch) {
				dispatch("input", input.value);
			},
			toggleCut() {
				this.show = this.show ? false : true;

				if (this.cuts.length == 0) {
					this.addCut()
				}
			},
			setState($event) {
				this.buttonState = $event.detail
			},
			addCut() {
				this.show = true;
                this.cuts = this.cuts.concat([{
					length: this.minCutLength,
					toleranceId: this.defaultTolerance.internalId,
                    quantity: 1
				}]);
            },
            addToCart() {
                // todo - what to do?
                alert("Todo - what to do? Cuts have been console.logged");
                console.log({cuts: JSON.parse(JSON.stringify(this.cuts))});
            },
			formattedPrice(price) {
				var anPrice = isNaN(parseFloat(price)) ? 0 : price;
				return this.currency + " " + new Intl.NumberFormat(this.culture, { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(anPrice);
            },
			availableLength(cut) {
				if (this.isStandardOrOutlet) {
					let quantity = cut?.quantity ?? 1;
					let length = this.maxCutLength == 0 ? 2147483647 : (this.maxCutLength - this.totalCutsLength + this.cutLength(cut));
					if (length == 0 || quantity == 0) return 0;
					return length / quantity;
				}
		
			},
			cutLength(cut) {
				if (cut) {
					var baseLength = parseInt(cut.quantity) * parseInt(cut.length);
					if (this.isStandardOrOutlet) {
						var tolerance = this.cutTolerance(cut);
						return parseInt(cut.quantity) * (parseInt(cut.length) + (tolerance == undefined ? 0 : tolerance.maxValue) + this.sawWidth );
					}
					else{
						return baseLength;
					}
				}
				else {
					return 0;
				}
			},
			cutIsValid(cut){
				return cut.length <= this.maxCutLength && cut.length >= this.minCutLength;
			},
			cutTolerance(cut){
				return this.allToleranceOptions.find(x=> x.internalId == cut.toleranceId);
			},
			cutToleranceDisplay(cut){
				var t = this.cutTolerance(cut);
				return t == undefined ? '' : t.display;
			},
			setQuantity(cut, add) {
                cut.quantity = Math.max(1, parseInt(cut.quantity) + parseInt(add));
			},
			showOverlay() {
				if (this.isLoggedIn == "False" && this.productType == "Custom") {
					return true
				}
				else {
					return false;
				}
			}
        }
    }
}
