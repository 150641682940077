module.exports = {
	xDiscountCode: () => {
		return {
			show: false,
			discountCodeList: [],
			discountInput: "",
			open() { return this.show = true },
			close() { return this.show = false },
			isOpen() { return this.show === true },
			addDiscountCode() {
				if (/\S/.test(this.discountInput) && this.discountCodeList.length === 0) {
					this.discountCodeList.push(this.discountInput)
					this.discountInput = ""
					this.close()
				}
			},
			removeDiscountCode(index) {
				this.discountCodeList = this.discountCodeList.filter((discountCode, discountCodeIndex) => index !== discountCodeIndex)
			}
		}
	}
}
