module.exports = {
	screens: {
		'xs': '375px',
		'sm': '36rem',
		'md': '48rem',
		'lg': '62rem',
		'xl': '90rem',
		'xxl': '100rem'
	}
}
