/// #if env != 'production'
/*GITNOCOMMIT*/
/// #endif

// Polyfills
import objectFitImages from "object-fit-images"
import objectFitVideos from "object-fit-videos"

objectFitImages()
objectFitVideos()

// Other
import "lazysizes"

import { tns } from "./../node_modules/tiny-slider/src/tiny-slider"
window.tns = tns

import flatpickr from "flatpickr"
window.flatpickr = flatpickr

// Based on: https://codepen.io/skttl/pen/abmWQbp
import { screens } from './../styles/tailwind/tailwind.screens'
window.screens = {}

Object.keys(screens).map(screen => {
	let mm = window.matchMedia(`(min-width:${screens[screen]})`)

	mm.addListener(event =>
		window.dispatchEvent(new CustomEvent('matchmedia', {
			'detail': {
				'screen': screen,
				'media': event.media,
				'matches': event.matches
			}
		}))
	)

	window.screens[screen] = mm
})

import Alpine from 'alpinejs'
window.Alpine = Alpine
//erstat med spruce
//import './store'



import { xCutOffsConfigurator } from './components/xCutOffsConfigurator'
window.xCutOffsConfigurator = xCutOffsConfigurator

import { xQuantitySpinner } from './components/xQuantitySpinner'
window.xQuantitySpinner = xQuantitySpinner

import { xAddToBasket } from './components/xAddToBasket'
window.xAddToBasket = xAddToBasket

import { xAsyncForm } from './components/xAsyncForm'
window.xAsyncForm = xAsyncForm

import { xOverflow } from './components/xOverflow'
window.xOverflow = xOverflow

import { xDiscountCode } from './components/xDiscountCode'
window.xDiscountCode = xDiscountCode

import { xImageModal } from './components/xImageModal'
window.xImageModal = xImageModal

import { xFavorites } from './components/xFavorites'
window.xFavorites = xFavorites

import { xButtonState } from './components/xButtonState'
window.xButtonState = xButtonState

import { xChangePackagingProduct } from './components/xChangePackagingProduct'
window.xChangePackagingProduct = xChangePackagingProduct

import { xPromoCode } from './components/xPromoCode'
window.xPromoCode = xPromoCode

import { xCheckout } from './components/xCheckout'
window.xCheckout = xCheckout

import { xMemberAddresses } from './components/xMemberAddresses'
window.xMemberAddresses = xMemberAddresses

import { xContactForm } from './components/xContactForm'
window.xContactForm = xContactForm

Alpine.start()
