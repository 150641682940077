module.exports = {
	xMemberAddresses: () => {
		return {
			formSpread: {
				['x-on:submit.prevent']: 'submit($dispatch)'
			},
			submitting: false,
			submit() {
				const $form = $(this.$el)
				if ($form.valid()) {
					this.fetchView()
				}
			},
			fetchView() {
				if (!this.submitting) {
					this.submitting = true

					let body = new FormData(this.$el)

					fetch(this.$el.action, {
						body,
						method: this.$el.method
					})
						.then(response => {
							if (!response.ok) {
								throw new Error('Network response was not ok');
							}
							return response.text()
						})
						.then(data => {
							let wrapper = document.querySelector('#checkoutWrapper')
							wrapper.innerHTML = data
						})
						.catch(error => {
							console.log(error)
						})
				}
			}
		}
	}
}
