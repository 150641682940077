export function xCheckout(useBillingAsShippingAddress, selectedShippingMethodId, selectedShippingMethod, selectedDeliveryOption) {
	return {
		useBillingAsShippingAddress: useBillingAsShippingAddress,
		selectedShippingMethodId: selectedShippingMethodId,
		shippingMethod: selectedShippingMethod,
		shippingMethodChoice: selectedDeliveryOption,
		pickedShippingDate: '',
		submitting: false,
		formSpread: {
			['x-on:submit.prevent']: 'submit($dispatch)'
		},
		submit() {
			const $form = $(this.$el)
			if ($form.valid()) {
				this.fetchView()
			}
		},
		fetchView() {
			if (!this.submitting) {
				this.submitting = true

				if (this.selectedShippingMethodId == "0137902c-e84b-493f-8d2b-33391b538d4f") {
					this.shippingMethodChoice = ''
					this.pickedShippingDate = ''
				}
				if (this.shippingMethodChoice == 'asap') {
					this.pickedShippingDate = ''
				}

				let body = new FormData(this.$el)
				fetch(this.$el.action, {
					body,
					method: this.$el.method
				})
					.then(response => {
						if (!response.ok) {
							throw new Error('Network response was not ok');
						}
						return response.text()
					})
					.then(data => {
						let wrapper = document.querySelector('#checkoutWrapper')
						wrapper.innerHTML = data

						this.submitting = false

						window.scrollTo(0, 0)
					})
					.catch(error => {
						console.log(error)
						this.submitting = false
					})
			}
		}
	}
}
