module.exports = {
	xContactForm: (id) => {
		return {
			formSpread: {
				['@submit.prevent']: 'submit($dispatch)'
			},
			submitting: false,
			submit($dispatch) {
				const $form = $(this.$el)
				if (!this.submitting && $form.valid()) {
					this.submitting = true
					let body = new FormData(this.$el)

					this.changeState($dispatch, { id, state: 'fetching' })
					fetch(this.$el.action, {
						body,
						method: this.$el.method
					})
						.then(response => {
							if (!response.ok) {
								throw new Error('Network response was not ok');
							}
							return response.text()
						})
						.then(() => {
							this.changeState($dispatch, { id, state: 'success' })
							this.resetState($dispatch, id)
						})
						.catch(error => {
							console.log(error)

							this.changeState($dispatch, { id, state: 'error' })
							this.resetState($dispatch, id)
						})
				}
			},
			changeState($dispatch, payload) {
				$dispatch(`change-state-${payload.id.toLowerCase()}`, payload.state)
			},
			resetState($dispatch, id) {
				setTimeout(() => {
					this.submitting = false

					this.$el.reset()

					$dispatch(`change-state-${id.toLowerCase()}`, "")
				}, 1500)
			}
		}
	}
}
