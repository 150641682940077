module.exports = {
	xPromoCode: () => {
		return {
			spread: {
				['@submit.prevent']: 'submit'
			},
			submitting: false,
			submit: function (event) {
				if (!this.submitting) {
					this.submitting = true;
					let body = new FormData(this.$el);

					fetch(this.$el.action, {
						body,
						method: this.$el.method
					})
						.then(response => {
							if (response.status == 200) {
								response.text().then((s) => {
									let wrapper = document.querySelector('#basketWrapper');
									wrapper.innerHTML = s;
								});
							}
							else {
								alert("promocode failed");
							}

							this.submitting = false;
						});
				}
			}
		}
	}
}
