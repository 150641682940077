module.exports = {
	xImageModal: () => {
		return {
			show: false,
			imageUrl: '',
			open() { return this.show = true },
			close() { return this.show = false },
			isOpen() { return this.show === true },
			openModal(event, dispatch) {
				this.imageUrl = event.currentTarget.src
				this.open()
				dispatch('body-lock', true)
			},
			closeModal(dispatch) {
				this.imageUrl = ''
				this.close()
				dispatch('body-lock', false)
			}
		}
	}
}
