module.exports = {
	xButtonState: () => {
		return {
			buttonState: "",
			disabled: false,
			setState($event) {
				this.buttonState = $event.detail
				this.disabled = this.buttonState == 'disabled' ? true : false
			}
		}
	}
}
