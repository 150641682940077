module.exports = {
	xAddToBasket: (sku) => {
		return {
			showPromt: false,
			orderTypeChosen: null,
			formSpread: {
				['@submit.prevent']: 'submit($dispatch)'
			},
			submitting: false,
			submit($dispatch) {
				if (this.showPromt && !this.orderTypeChosen) {
				}
				else if (!this.submitting) {
					this.submitting = true
					let body = new FormData(this.$el)
					if (body.has("form.CutOffsResponse")) {
						let cutOffs = JSON.parse(body.get("form.CutOffsResponse"))
						body.delete("form.CutOffsResponse")
						Object.keys(cutOffs).map(key => {
							if (key !== "Lines") {
								body.set(`form.CutOffsResponse.${key}`, cutOffs[key])
							}
						})

						cutOffs.Lines.map((line, i) => {
							Object.keys(line).map(key => {
								body.set(`form.CutOffsResponse.Lines[${i}].${key}`, line[key])
							})
						})
					}

					this.changeState($dispatch, { sku, state: 'fetching' })
					fetch(this.$el.action, {
						body,
						method: this.$el.method
					})
						.then(response => {
							if (!response.ok) {
								throw new Error('Network response was not ok');
							}
							return response.text()
						})
						.then(data => {
							let wrapper = document.querySelector('#modalBasketWrapper')
							if (wrapper == null) {
								wrapper = document.querySelector('#basketWrapper')
							}
							if (document.querySelector("#savedBasketWrapper") != null) {
								wrapper = document.querySelector("#savedBasketWrapper")
								document.body.classList.remove('overflow-hidden')
							}

							if (data !== "success") {
								wrapper.innerHTML = data
							}

							this.changeState($dispatch, { sku, state: 'success' })
							this.resetState($dispatch, sku)

							$dispatch('product-added', true)
						})
						.catch(error => {
							console.log(error)

							this.changeState($dispatch, { sku, state: 'error' })
							this.resetState($dispatch, sku)
						})
				}
			},
			changeState($dispatch, payload) {
				$dispatch(`change-state-${payload.sku.toLowerCase()}`, payload.state)
			},
			resetState($dispatch, sku) {
				setTimeout(() => {
					this.submitting = false

					$dispatch(`change-state-${sku.toLowerCase()}`, "")
				}, 1500)
			}
		}
	}
}
